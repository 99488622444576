import _ from 'lodash';
import moment from 'moment';
import Immutable from 'immutable';
import NumberFormat from 'core/i18n/internal/number';
import { BEFORE_SESSION_CHECK } from 'core/boot/constants';
import { isDefined } from 'core/util/lang';
import { doRequest } from 'core/backend/doRequest';
import { applicationState } from 'core/state/applicationState';
import { SETTING_UP_SYSTEMS } from './constants';
import { fetchTranslations } from 'core/i18n/translate';

export function setupLocaleInformation(availableLocales) {
  _.forEach(availableLocales, locale => {
    NumberFormat.locale[locale.value] = {
      DECIMAL_SEP: locale.decimalSeperator,
      GROUP_SEP: locale.groupSeperator,
      PERCENT: locale.percent,
      ZERO_DIGIT: locale.zeroDigit,
      PLUS_SIGN: '+',
      MINUS_SIGN: locale.minusSign,
      EXP_SYMBOL: locale.exponentSeparator,
      PERMILL: locale.perMill,
      INFINITY: locale.infinity,
      NAN: locale.naN,
      DECIMAL_PATTERN: locale.decimalPattern,
      SCIENTIFIC_PATTERN: '#E0',
      PERCENT_PATTERN: locale.percentPattern,
      CURRENCY_PATTERN: locale.currencyPattern,
      DEF_CURRENCY_CODE: locale.currencyCode,
      DEF_CURRENCY_SIGN: locale.currencySign
    };

    applicationState
      .cursor()
      .mergeIn(
        ['i18n', locale.value, 'formatStrings'],
        Immutable.fromJS(_.pickBy(locale, (value, key) => _.endsWith(key, 'Pattern')))
      );
    applicationState.cursor().setIn(['i18n', locale.value, 'detailedLocaleInformation'], Immutable.fromJS(locale));

    // set moment formatting settings as well

    moment.locale(locale.value, {
      months: _.without(locale.months, ''),
      monthsShort: _.without(locale.shortMonths, ''),
      weekdays: _.without(locale.weekdays, ''),
      weekdaysShort: _.without(locale.shortWeekdays, ''),
      weekdaysMin: _.without(locale.shortWeekdays, ''),
      week: {
        dow: locale.firstDayOfWeek
      }
    });
  });
}

export function setupAvailableLocales(availableLocales) {
  applicationState.cursor(['i18n']).set('availableLocales', Immutable.fromJS(availableLocales));
}

export function localeHandler() {
  const i18nCacheKey = applicationState.cursor().getIn(['cacheKeys', 'i18n', 'key']);

  if (!isDefined(i18nCacheKey)) {
    throw new Error('no i18n cache key defined');
  }

  return doRequest('{context}/{api}/{version}/i18n/{cacheKey}/locales', {
    urlVariables: {
      cacheKey: i18nCacheKey
    }
  })
    .then(availableLocalesResult => {
      const availableLocales = availableLocalesResult.data.availableLocales;

      setupLocaleInformation(availableLocales);

      setupAvailableLocales(availableLocales);
    })
    .catch(error => {
      console.log('failed to retrieve locale information', error);
    });
}

export function register(initializer) {
  initializer.callback(BEFORE_SESSION_CHECK, localeHandler);
  initializer.callback(SETTING_UP_SYSTEMS, () => fetchTranslations());
}
