import PropTypes from 'prop-types';

import React from 'react';
import BootScreenInfo from 'core/boot/components/bootScreenInfo';
import BootScreenFooter from 'core/boot/components/bootScreenFooter';
import { applicationState } from 'core/state/applicationState';

export default class BootScreen extends React.Component {
  static key = 'BootScreen';

  static propTypes = {
    children: PropTypes.node
  };

  render() {
    const appName = applicationState.cursor('configuration').get('appName');

    return (
      <div className="boot-screen">
        <div className="boot-screen-box">
          <div className="company-logo" />
          <div className="app-logo" />
          <div className="app-name">{appName}</div>

          <hr />
          <div className="context">{this.props.children}</div>
          <BootScreenInfo />
          <BootScreenFooter />
        </div>
      </div>
    );
  }
}
