export const ERROR_TYPES = {
  VALIDATION: 'validation',
  INVALID_FORM_INPUT: 'invalid_form_input',
  NETWORK: 'network',
  TIMEOUT: 'timeout',
  ERROR: 'error',
  UNKNOWN: 'unknown',
  BACKEND: 'backend',
  NOT_FOUND: 'not_found',
  FORBIDDEN: 'forbidden',
};
