import React from 'react';
import classNames from 'classnames';
import { ERROR_INITIALIZATION } from 'core/boot/constants';
import { get } from 'core/util/lang';
import { applicationState } from 'core/state/applicationState';

export default class BootScreenInfo extends React.Component {
  static key = 'BootScreenInfo';

  render() {
    const state = get(applicationState, 'boot.state');
    const updateState = get(applicationState, 'backend.updateState');

    let info;

    if (updateState === 'PENDING') {
      info = (
        <div className="info">
          <span id="splash-info-text" className={classNames({ alert: false })}>
            {get(applicationState, 'boot.components.update.pending')}
          </span>
        </div>
      );
    } else if (state === ERROR_INITIALIZATION) {
      info = (
        <div className="info">
          <span id="splash-info-text" className={classNames({ alert: true })}>
            {get(applicationState, 'boot.error') || get(applicationState, 'boot.components.update.error')}
          </span>
        </div>
      );
    } else {
      info = <div className="info">&nbsp;</div>;
    }

    return info;
  }
}
