import _ from 'lodash';
import { BEFORE_SESSION_CHECK } from 'core/boot/constants';
import { doRequest } from 'core/backend/doRequest';
import { resolveData } from 'core/util/promises';
import { get, set } from 'core/util/lang';
import { applicationState } from 'core/state/applicationState';
import Immutable from 'immutable';

export const CACHE_NAMES = {
  IMAGES: 'images',
  COURSEGUIDE: 'courseguide',
  DASHBOARD: 'dashboard',
  ADMIN: 'admin',
  I18N: 'i18n',
  CONFIGURATION: 'configuration',
  COMPONENT_CONFIGURATION: 'componentConfiguration'
};

export function setCacheKeys(cacheKeys) {
  const cacheKeysTransformed = _.transform(
    cacheKeys,
    (transform, cacheKeyEntry) => {
      transform[cacheKeyEntry.name] = cacheKeyEntry;
    },
    {}
  );

  applicationState.cursor().set('cacheKeys', Immutable.fromJS(cacheKeysTransformed));
}

export function getCacheKey(name) {
  return get(applicationState, ['cacheKeys', name, 'key']);
}

export function updateCacheKey(name, value) {
  return set(applicationState, ['cacheKeys', name, 'key'], value);
}

export function cacheKeyHandler() {
  return doRequest('{context}/{api}/{version}/cachekeys')
    .then(resolveData)
    .then(setCacheKeys);
}

export function register(initializer) {
  initializer.callback(BEFORE_SESSION_CHECK, cacheKeyHandler);
}
