import React from 'react';
import { get } from 'core/util/lang';
import { applicationState } from 'core/state/applicationState';

export default class BootLoaderScreen extends React.Component {
  static key = 'BootLoaderScreen';

  render() {
    return (
      <div>
        <h3>{get(applicationState, 'boot.components.loader.text')}</h3>
      </div>
    );
  }
}
