import React from 'react';
import _ from 'lodash';
import BootScreen from 'core/boot/components/bootScreen';
import BootLoader from 'core/boot/components/bootLoaderScreen';
import ReactDOM from 'react-dom';
import { initialize } from 'core/boot/initialize';
import {
  BEFORE_SETTING_UP_SYSTEMS,
  ERROR_INITIALIZATION,
  FINISHED_INITIALIZATION,
  START_INITIALIZATION,
} from 'core/boot/constants';
import applicationStateDefaultsHandler from 'core/boot/applicationStateDefaultsHandler';
import { applicationState, applicationStructure } from 'core/state/applicationState';
import { get, isValueNotEmpty, set } from 'core/util/lang';
import styleContext from 'selfServiceApp/app/styleContext.json';

set(applicationState, 'styling.reference', styleContext);

let loadingApplicationBundle;

const i18nFetchKeys = [
  'clientcore',
  'selfserviceapp',
  'customerSpecific.selfserviceapp',
  'learning',
  'customerSpecific.learning',
  'configuration',
  'customerSpecific.configuration',
  'competency',
  'customerSpecific.competency',
];

let clientInitializer;
switch (get(window.stateOverride, 'configuration.deployment')) {
  case 'embedded': {
    clientInitializer = initialize.embedded().callback(
      START_INITIALIZATION,
      applicationStateDefaultsHandler({
        i18n: {
          fetchKeys: i18nFetchKeys,
        },
        configuration: {
          application: 'selfserviceapp',
        },
      })
    );
    break;
  }
  case 'standalone':
  default: {
    clientInitializer = initialize.anonymous().callback(
      START_INITIALIZATION,
      applicationStateDefaultsHandler({
        i18n: {
          currentLocale: 'de_AT',
          fetchKeys: i18nFetchKeys,
        },
        boot: {
          components: {
            loader: {
              text: 'Lade Applikation...',
            },
            footer: {
              infoText: 'made with ♥ by infoniqa',
            },
            update: {
              error: 'Engage ist derzeit nicht erreichbar.',
            },
          },
        },
        configuration: {
          appName: 'Self Service App',
          application: 'selfserviceapp',
          cacheKey: 'selfserviceapp',
          backend: {
            contextPath: '/engage',
            checkStatusPath: '{context}/{api}/{version}/engageUpdateStatus',
            textModuleType: 'CGNEWTEXT',
            API: {
              base: 'api',
              version: 'v1',
            },
          },
          version: {
            number: process.env.version || '',
            environment: process.env.environment || '',
            build: process.env.build || '',
          },
        },
      })
    );
  }
}

// TODO: extract
function reRenderBootApplication() {
  ReactDOM.render(
    <BootScreen>
      <BootLoader />
    </BootScreen>,
    document.getElementById('application')
  );
}

clientInitializer
  .callback(START_INITIALIZATION, () => {
    applicationStructure.on('next-animation-frame', reRenderBootApplication);
  })
  .callback(BEFORE_SETTING_UP_SYSTEMS, () => {
    // load application bundles
    //noinspection JSAnnotator
    loadingApplicationBundle = import('selfServiceApp');
  })
  .callback(FINISHED_INITIALIZATION, () => {
    // TODO: used during development to define translations without the need of the engage
    // backend. BEFORE SUBMIT PLEASE MOVE THEM TO ENGAGE
    // there exists an changeset generation script to make that process a little simpler

    const mssI18NMock_DE_AT = require('./mock_de_at.json');
    const mssI18NMock_EN_US = require('./mock_en_us.json');

    if (isValueNotEmpty(mssI18NMock_DE_AT)) {
      applicationState.cursor().mergeDeepIn(['i18n', 'de_AT'], mssI18NMock_DE_AT);
    }
    if (isValueNotEmpty(mssI18NMock_EN_US)) {
      applicationState.cursor().mergeDeepIn(['i18n', 'en_US'], mssI18NMock_EN_US);
    }
  })
  .boot()
  .then(() => {
    return loadingApplicationBundle.tap((selfServiceAppBundle) => {
      selfServiceAppBundle.setup().finally(() => {
        applicationStructure.off('next-animation-frame', reRenderBootApplication);
      });
    });
  })
  .catch((error) => {
    // no rethrow because setup is done
    set(applicationState, 'boot.state', ERROR_INITIALIZATION);

    if (_.isString(error)) {
      console.log('error during boot initialization', error);
      set(applicationState, 'boot.error', error);
    } else if (isValueNotEmpty(error.message)) {
      console.log('error during boot initialization', error);
      set(applicationState, 'boot.error', error.message);
    }
  });
