/**
 * Indicates an error during fetch() API calls (network)
 *
 * Error receipt:
 * needed because babel cannot subclass native Error objects without hacking the __proto__ chain
 * http://stackoverflow.com/questions/33870684/why-doesnt-instanceof-work-on-instances-of-error-subclasses-under-babel-node
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
 *
 * @param message
 * @constructor
 */
export default function FetchError(message) {
  this.name = 'FetchError';
  this.message = message;
  this.stack = new Error().stack;
}
FetchError.prototype = Object.create(Error.prototype);
FetchError.prototype.constructor = FetchError;
