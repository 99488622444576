import { applicationState } from 'core/state/applicationState';
import { get, isDefined, isValueNotEmpty } from 'core/util/lang';
import _ from 'lodash';
import ReactDOM from 'react-dom';

export function isStorybook() {
  // eslint-disable-next-line no-undef
  return isDefined(window.STORYBOOK_ENV);
}

export function isTestRun() {
  return process.env.NODE_ENV === 'test' || get(applicationState, 'configuration.testRun') === true;
}

export function maintainComponentName(componentDomNode, componentDefinition) {
  const componentName = componentDefinition.name;
  if (isDefined(componentDomNode) && _.isFunction(componentDomNode.getAttribute)) {
    const currentAttributeValues = _.split(componentDomNode.getAttribute('data-componentname'), ' ');
    currentAttributeValues.push(componentName);
    componentDomNode.setAttribute('data-componentname', _.join(_.compact(_.uniq(currentAttributeValues)), ' '));
  }
}

export function setDOMNodeComponentName(componentDefinition) {
  let doSetNameTimerId = null;

  return function(componentRef) {
    if (isTestRun()) {
      if (componentRef === null) {
        if (isValueNotEmpty(doSetNameTimerId)) {
          window.clearTimeout(doSetNameTimerId);
          doSetNameTimerId = null;
        }
      } else {
        const doSetName = () => {
          let componentDomNode = ReactDOM.findDOMNode(componentRef);
          if (componentDomNode !== null) {
            maintainComponentName(componentDomNode, componentDefinition);
          } else {
            if (isValueNotEmpty(doSetNameTimerId)) {
              // polling already running
            } else {
              doSetNameTimerId = window.setTimeout(() => {
                doSetNameTimerId = null;
                doSetName();
              }, 100);
            }
          }
        };
        doSetName();
      }
    }
  };
}
