/*
 service part, the UI implementation is part of the shell
 the shell visualizes the state in application state.
 */

import { applicationState } from 'core/state/applicationState';
import { get, isDefined, isValueEmpty } from 'core/util/lang';
import invariant from 'invariant';
import _ from 'lodash';
import { isTestRun } from 'core/testSupport/testRun';
import { translate } from 'core/i18n/translate';
import { getConfirmation } from 'history/DOMUtils';
import { ReactNode } from 'react';
import { DialogProps } from 'core/component/control/overlay/dialog';

export enum SEVERITY {
  ERROR = 'ERROR',
  WARN = 'WARN',
  OK = 'OK',
}

const notificationReference = applicationState.reference(['shell', 'notification']);
const dialogReference = applicationState.reference(['shell', 'dialogs']);

function applyDefaultsToNotify(options: NotifyOptions): InternalNotifyOptions {
  return _.assign(
    {},
    {
      notificationKey: _.uniqueId('notificationKey'),
      autoHideDuration: isTestRun() ? 20000 : 5000,
    },
    options
  );
}

// message: text to display
export type NotifyOptions = {
  message: ReactNode;
  severity?: SEVERITY;
  open?: boolean;
  action?: ReactNode | ((requestClose: () => void) => ReactNode);
  autoHideDuration?: number | null;
};

type InternalNotifyOptions = {
  notificationKey?: string;
} & NotifyOptions;

export function notify(options: NotifyOptions) {
  invariant(isDefined(options), 'please specify options for notification');
  invariant(isDefined(get(options, 'message')), 'please specify a message to show for the notification');

  notificationReference.cursor().update(() => applyDefaultsToNotify(options));
}

export function getCurrentNotificationOptions(): InternalNotifyOptions {
  return notificationReference.cursor().deref();
}

// title: headline
// children: content of the dialog
type BaseDialogConfiguration = Pick<
  DialogProps,
  'className' | 'classes' | 'title' | 'contentProps' | 'modal' | 'children'
> & {
  actions?: { label: ReactNode; onClick?: () => void }[];
};

type RenderContentProp = { renderContent: (param: { onRequestClose: () => void }) => ReactNode };
type RenderDialogProp = {
  renderDialog: (props: BaseDialogConfiguration, show: boolean, onRequestClose: () => void) => ReactNode;
};
type DialogConfiguration = BaseDialogConfiguration & Partial<RenderContentProp & RenderDialogProp>;

export function showDialog(dialogConfiguration: BaseDialogConfiguration): void;
export function showDialog(dialogConfiguration: BaseDialogConfiguration & RenderContentProp): void;
export function showDialog(dialogConfiguration: BaseDialogConfiguration & RenderDialogProp): void;
export function showDialog(dialogConfiguration: DialogConfiguration): void {
  invariant(isDefined(dialogConfiguration), 'please specify an dialog configuration to render');

  dialogReference.cursor().set(dialogConfiguration);
}

export function getCurrentDialogConfiguration(): DialogConfiguration {
  return dialogReference.cursor().deref();
}

export function clearDialogConfiguration() {
  dialogReference.cursor().set(null);
}

export const getOkAbortConfirmation: typeof getConfirmation = function (message, callback) {
  showDialog({
    title: translate('clientcore.service.shell.notification.confirmation.title'),
    children: message,
    actions: [
      {
        label: translate('clientcore.service.shell.notification.confirmation.abort'),
        onClick() {
          callback(false);
        },
      },
      {
        label: translate('clientcore.service.shell.notification.confirmation.ok'),
        onClick() {
          callback(true);
        },
      },
    ],
  });
};

export const getLeavePageConfirmation: typeof getConfirmation = function (message, callback) {
  showDialog({
    title: translate('clientcore.service.shell.notification.confirmation.title'),
    children: message,
    actions: [
      {
        label: translate('clientcore.service.shell.notification.confirmation.abort'),
        onClick() {
          callback(false);
        },
      },
      {
        label: translate('clientcore.service.shell.notification.confirmation.leavePage'),
        onClick() {
          callback(true);
        },
      },
    ],
  });
};

export function notifyError(error: { message: ReactNode; data?: any; type?: any }) {
  let message = error?.message;
  if ('PersonNotAssignedException' === error?.data?.type) {
    message = translate('selfserviceapp.boot.userHasNoEmployee');
  } else if (
    'CustomSpecificDmsServiceException' === error?.data?.type ||
    'CustomSpecificDmsServiceException' === error?.type
  ) {
    message = translate('selfserviceapp.error.specificDmsError', [error?.data?.data?.dmsServicePlugin]);
  } else if (
    'InvalidDocumentInternalCategoryCodeException' === error?.data?.type ||
    'InvalidDocumentInternalCategoryCodeException' === error?.type
  ) {
    let data = [];
    data.push(error?.data?.data?.dmsServicePlugin);
    data.push(error?.data?.data?.documentData?.id);
    const internalCategoryCode = error?.data?.data?.documentData?.internalCategoryCode;
    message = translate(
      isValueEmpty(internalCategoryCode)
        ? 'selfserviceapp.error.missingDocumentInternalCategoryCode'
        : 'selfserviceapp.error.invalidDocumentInternalCategoryCode',
      data
    );
  } else if ('DocumentQuarantineException' === error?.data?.type || 'DocumentQuarantineException' === error?.type) {
    const data = error?.data?.data ?? error?.data;
    message = translate(data?.messageKey, [data?.fileName]);
  }
  notify({
    message: message,
    severity: SEVERITY.ERROR,
  });
}
