import 'react-app-polyfill/stable';
import Promise from 'bluebird';
import { getDeviceInfo, BROWSER_NAMES } from 'core/util/device';
import { isDefined } from 'core/util/lang';

// adapt webpack public path
// @ts-ignore
if (isDefined(window.__webpack_public_path__)) {
  // eslint-disable-next-line
  // @ts-ignore
  __webpack_public_path__ = window.__webpack_public_path__;
}

// use bluebird as Promise library
// @ts-ignore
window.Promise = Promise;

let longStackTraces;
if (process.env.NODE_ENV === 'production') {
  longStackTraces = false;
} else {
  const deviceInfo = getDeviceInfo();

  // never use long stack traces in IE because of huge performance penalty.
  longStackTraces = !(deviceInfo.is(BROWSER_NAMES.IE) || deviceInfo.is(BROWSER_NAMES.EDGE));
}

// configure promises
Promise.config({
  // Enable warnings.
  warnings: process.env.NODE_ENV === 'development',
  // Enable long stack traces.
  longStackTraces: longStackTraces,
  // Enable cancellation.
  cancellation: true
});
