import getLogger, { substitute, registerGlobalTransport, setLevel } from 'core/logging/loggerBase';

export { substitute, registerGlobalTransport, setLevel };
export default getLogger;

/* eslint-disable no-console */

// console transport is always activated
registerGlobalTransport(logEntry => {
  if (console) {
    // strange problem in Microsoft Edge
    // following code
    // var xx = console.log; xx();
    // causes a security violation error in edge
    // calling the console.log directly works as expected

    if (console[logEntry.level]) {
      console[logEntry.level](logEntry.message, ...logEntry.data);
    } else if (console.log) {
      console.log(logEntry.message, ...logEntry.data);
    }
  }
});
