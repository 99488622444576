import { BEFORE_SESSION_CHECK, COMPLETED, SESSION_CHECK } from 'core/boot/constants';
import session from 'core/state/session';
import { get, isValueNotEmpty, set } from 'core/util/lang';
import { applicationState } from 'core/state/applicationState';
import { localeHandler } from 'core/boot/localeHandler';

function embeddedUpdateHandler() {
  // we don't need to check anything, because the jsp will only be loaded when updates are already applied and backend is
  // in good shape therefor skip the check
  set(applicationState, 'backend.updateState', COMPLETED);
}

function embeddedSessionHandler() {
  const receivedSessionInformation = get(window, 'stateOverride.boot.session');
  return session.setEmbeddedInformation(receivedSessionInformation);
}

function embeddedErrorHandler() {
  const embeddedError = get(applicationState, 'boot.embedded.error');
  if (isValueNotEmpty(embeddedError)) {
    set(applicationState, 'boot.components.update.error', embeddedError);
    throw new Error('cannot boot because application is already in error state');
  }
}

export function register(initializer) {
  initializer.callback(BEFORE_SESSION_CHECK, embeddedErrorHandler);
  initializer.callback(BEFORE_SESSION_CHECK, embeddedUpdateHandler);
  initializer.callback(BEFORE_SESSION_CHECK, localeHandler);
  initializer.callback(SESSION_CHECK, embeddedSessionHandler);
}
