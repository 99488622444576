import { doRequest } from 'core/backend/doRequest';
import { resolveData } from 'core/util/promises';
import { get } from 'core/util/lang';
import { BEFORE_SESSION_CHECK } from './constants';
import { HcmStatus } from 'core/types/hcmStatus';

export function checkStatus() {
  function positiveReturnCode(response) {
    return resolveData(response).then((state) => {
      if (get(state, 'status') !== HcmStatus.AVAILABLE) {
        throw new Error(
          `Received impossible state from engage! Should be "AVAILABLE" but is "${get(state, 'status')}"`
        );
      }
    });
  }

  function negativeReturnCode(responseError) {
    if (responseError.response.status === 503) {
      const status = get(responseError.data, 'status');
      switch (status) {
        case HcmStatus.MAINTENANCE_MODE: {
          throw new Error(`Engage is in maintenance mode`);
        }
        case HcmStatus.RESTRICTED_MODE: {
          throw new Error(`Engage is in restricted mode`);
        }
        case HcmStatus.UPDATE_PENDING: {
          throw new Error(`Engage is in update pending mode`);
        }
        default: {
          throw new Error(`Engage Status endpoint returned unrecognized state "${status}"`);
        }
      }
    } else {
      throw new Error(`Engage Status endpoint is not reachable`);
    }
  }

  return doRequest('{context}/common/engageStatus').then(positiveReturnCode, negativeReturnCode);
}

export function register(initializer) {
  initializer.callback(BEFORE_SESSION_CHECK, checkStatus);
}
