import { SESSION_CHECK } from 'core/boot/constants';
import session from 'core/state/session';

export function sessionHandler() {
  return session.check();
}

export function register(initializer) {
  initializer.callback(SESSION_CHECK, sessionHandler);
}
