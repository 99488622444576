import _ from 'lodash';

/**
 * Indicates an generic client error implementation
 *
 * Error receipt:
 * needed because babel cannot subclass native Error objects without hacking the __proto__ chain
 * http://stackoverflow.com/questions/33870684/why-doesnt-instanceof-work-on-instances-of-error-subclasses-under-babel-node
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
 *
 * @param errorDescription
 * @constructor
 */
export default function WrappedError(errorDescription) {
  this.name = 'WrappedError';
  // assigned explicitly for typescript type inference
  this.message = errorDescription.message;
  _.assign(this, errorDescription);
  this.stack = new Error().stack;
  this.handled = false;
}
WrappedError.prototype = Object.create(Error.prototype);
WrappedError.prototype.constructor = WrappedError;
