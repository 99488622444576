import _ from 'lodash';
import Promise from 'bluebird';
import { get, isDefined, isValueNotEmpty, PotentialPropertyPath, toPath } from 'core/util/lang';
import { substitute } from 'core/logging/loggerBase';
import invariant from 'invariant';
import { translate } from 'core/i18n/translate';
import moment from 'moment';
import { FormContext } from 'core/component/control/form/form';
import { assertNever } from 'core/util/assertion';

export type ValidatorFunction = (
  toValidate: any,
  wholeObject: any,
  property: string,
  propertyPath: PotentialPropertyPath
) => Promise<void>;

export type ValidationConstraints = {
  [validatorName: string]: ValidatorFunction;
};

export enum ErrorLevel {
  ERROR = 'error',
}

type ErrorConfig = {
  validator: unknown;
  value: any;
  reason: string;
  reasonKey: string;
  reasonAttributes?: any[] | object;
  property: string;
  propertyPath: string | string[];
  level?: ErrorLevel;
};

function isMaxLengthValidationError(error: { message: string }): boolean {
  return _.startsWith(error.message, 'Size.');
}

function isMaxValueValidationError(error: { message: string }): boolean {
  return _.startsWith(error.message, 'DecimalMax.');
}

export function getMessage(error: { message: string }): string {
  let isMaxLengthError = isMaxLengthValidationError(error);
  let isMaxValueError = isMaxValueValidationError(error);

  if (isMaxLengthError || isMaxValueError) {
    let errorKey: string;
    if (isMaxLengthError) {
      errorKey = 'clientcore.validation.backendValidation.exceedsMaxLength';
    } else {
      //isMaxValueError
      errorKey = 'clientcore.validation.backendValidation.exceedsMaxValue';
    }
    return translate(errorKey);
  } else {
    return error.message;
  }
}

export function succeeded(): Promise<void> {
  return Promise.resolve();
}

export function failed(errorConfig: ErrorConfig): Promise<void> {
  invariant(!_.isEmpty(errorConfig.validator), 'specify a validator name for validation error');
  invariant(!_.isEmpty(errorConfig.reason), 'specify a reason as former description for the validation error');
  invariant(!_.isEmpty(errorConfig.reasonKey), 'specify a i18n translation key for validation error');

  return Promise.reject({
    validator: errorConfig.validator,
    value: errorConfig.value,
    reason: errorConfig.reason,
    reasonKey: errorConfig.reasonKey,
    reasonAttributes: errorConfig.reasonAttributes,
    property: errorConfig.property,
    propertyPath: errorConfig.propertyPath,
    level: errorConfig.level || ErrorLevel.ERROR,
  });
}

function promiseTreatment(validationPromises: Promise<any>[]): Promise<void> {
  const allSettled = Promise.all(
    validationPromises.map((promise) => {
      return promise.reflect();
    })
  );
  const filteredPromises = Promise.reduce(
    allSettled,
    (accumulator, promise) => {
      if (promise.isRejected()) {
        const reason = promise.reason();
        if (_.isArray(reason)) {
          _.forEach(reason, (item) => {
            accumulator.push(item);
          });
        } else {
          accumulator.push(reason);
        }
      }
      return accumulator;
    },
    []
  );

  return filteredPromises.then((reducedPromises) => {
    if (_.size(reducedPromises) > 0) {
      return Promise.reject(reducedPromises);
    } else {
      return Promise.resolve();
    }
  });
}

function buildPropertyPath(fieldName: string, propertyPath: PotentialPropertyPath = ''): string[] {
  return _.concat(toPath(propertyPath), [fieldName]);
}

type ValidatorsWithoutConfig = Exclude<keyof Validators, 'type' | 'regexp'>;

type Validators = {
  string: () => ValidatorFunction;
  boolean: () => ValidatorFunction;
  number: () => ValidatorFunction;
  integer: () => ValidatorFunction;
  date: () => ValidatorFunction;
  object: (configuration?: { [property: string]: ValidationConstraints }) => ValidatorFunction;
  array: () => ValidatorFunction;
  type: (configuration: ValidatorsWithoutConfig | { type: ValidatorsWithoutConfig }) => ValidatorFunction;
  regexp: (configuration: string | RegExp) => ValidatorFunction;
};

export const validators: Validators = {
  string() {
    return (toValidate, wholeObject, property, propertyPath) => {
      if (_.isString(toValidate)) {
        return succeeded();
      } else {
        return failed({
          validator: 'string',
          value: toValidate,
          reason: 'not a string',
          reasonKey: 'clientcore.validation.string',
          property: property,
          propertyPath: propertyPath,
        });
      }
    };
  },
  boolean() {
    return (toValidate, wholeObject, property, propertyPath) => {
      if (_.isBoolean(toValidate)) {
        return succeeded();
      } else {
        return failed({
          validator: 'boolean',
          value: toValidate,
          reason: 'not a boolean',
          reasonKey: 'clientcore.validation.boolean',
          property: property,
          propertyPath: propertyPath,
        });
      }
    };
  },
  number() {
    return (toValidate, wholeObject, property, propertyPath) => {
      if (isValueNotEmpty(toValidate)) {
        const number = _.toNumber(toValidate);
        if (isDefined(number)) {
          return succeeded();
        } else {
          return failed({
            validator: 'number',
            value: toValidate,
            reason: 'not a number',
            reasonKey: 'clientcore.validation.number',
            property: property,
            propertyPath: propertyPath,
          });
        }
      } else {
        // no value is a valid state
        return succeeded();
      }
    };
  },
  integer() {
    return (toValidate, wholeObject, property, propertyPath) => {
      if (isValueNotEmpty(toValidate)) {
        const number = _.toNumber(toValidate);
        if (isDefined(number) && number % 1 === 0) {
          return succeeded();
        } else {
          return failed({
            validator: 'integer',
            value: toValidate,
            reason: 'not an integer',
            reasonKey: 'clientcore.validation.integer',
            property: property,
            propertyPath: propertyPath,
          });
        }
      } else {
        // no value is a valid state
        return succeeded();
      }
    };
  },
  date() {
    return (toValidate, wholeObject, property, propertyPath) => {
      if (isValueNotEmpty(toValidate)) {
        if (moment(toValidate).isValid()) {
          return succeeded();
        } else {
          return failed({
            validator: 'date',
            value: toValidate,
            reason: 'not a date',
            reasonKey: 'clientcore.validation.date',
            property: property,
            propertyPath: propertyPath,
          });
        }
      } else {
        // no value is a valid state
        return succeeded();
      }
    };
  },
  object(configuration) {
    return (toValidate, wholeObject, property, propertyPath) => {
      if (_.isObject(toValidate)) {
        if (isDefined(configuration)) {
          const validationPromises = _.map(configuration, (validators, fieldName) => {
            return callValidator(
              toValidate[fieldName],
              wholeObject,
              validators,
              fieldName,
              buildPropertyPath(fieldName, propertyPath)
            );
          });

          // wait for all promises to settle and filter the rejected once.
          return promiseTreatment(validationPromises);
        } else {
          return succeeded();
        }
      } else {
        return failed({
          validator: 'object',
          value: toValidate,
          reason: 'not an object',
          reasonKey: 'clientcore.validation.object',
          property: property,
          propertyPath: propertyPath,
        });
      }
    };
  },
  array() {
    return (toValidate, wholeObject, property, propertyPath) => {
      if (_.isArray(toValidate)) {
        return succeeded();
      } else {
        return failed({
          validator: 'array',
          value: toValidate,
          reason: 'not an array',
          reasonKey: 'clientcore.validation.array',
          property: property,
          propertyPath: propertyPath,
        });
      }
    };
  },
  type(configuration) {
    let type: ValidatorsWithoutConfig;
    if (_.isString(configuration)) {
      type = configuration;
    } else if (isDefined(configuration) && typeof configuration === 'object' && 'type' in configuration) {
      type = configuration.type;
    }

    switch (type) {
      case 'boolean':
        return validators.boolean();
      case 'integer':
        return validators.integer();
      case 'number':
        return validators.number();
      case 'string':
        return validators.string();
      case 'object':
        return validators.object();
      case 'array':
        return validators.array();
      case 'date':
        return validators.date();
      default:
        assertNever(type, `not supported type ${configuration}`);
    }
  },
  regexp(configuration) {
    let regExp: RegExp;
    if (_.isRegExp(configuration)) {
      regExp = configuration;
    } else if (_.isString(configuration) && !_.isEmpty(configuration)) {
      regExp = new RegExp(configuration);
    } else {
      throw new Error(substitute('not supported regular expression %o', [configuration]));
    }

    return (toValidate, wholeObject, property, propertyPath) => {
      if (regExp.test(toValidate)) {
        return succeeded();
      } else {
        return failed({
          validator: 'regexp',
          value: toValidate,
          reason: substitute('regular expression %s does not match value %o', [regExp, toValidate]),
          reasonKey: 'validator.reason.regexp',
          property: property,
          propertyPath: propertyPath,
        });
      }
    };
  },
};

function callValidator(
  toValidate: any,
  wholeObject: any,
  constraint: ValidationConstraints,
  property: string = '',
  propertyPath: PotentialPropertyPath = ''
): Promise<void> {
  if (isDefined(constraint)) {
    const validationPromises = _.map(constraint, (validator, validatorName) => {
      if (_.isFunction(validator)) {
        return Promise.method(validator)(toValidate, wholeObject, property, propertyPath);
      } else {
        return Promise.reject({
          value: toValidate,
          reason: `validator ${validatorName} is no validator`,
          property,
          propertyPath,
        });
      }
    });

    // wait for all promises to settle and filter the rejected once.
    return promiseTreatment(validationPromises);
  } else {
    return Promise.resolve();
  }
}

export default function validate(toValidate: any, constraints: ValidationConstraints): Promise<void> {
  return callValidator(toValidate, toValidate, constraints);
}

export function validateProperty(
  toValidate: any,
  wholeObject: any,
  constraints: ValidationConstraints,
  propertyName: string,
  propertyPath: PotentialPropertyPath
): Promise<void> {
  return callValidator(toValidate, wholeObject, constraints, propertyName, propertyPath);
}

export function isMandatoryProperty(formContext: FormContext, propertyName: string): boolean {
  const bindingForControl = _.find(get(formContext, 'bindings'), (entry) => {
    return _.isEqual(toPath(entry.propertyPath), toPath(propertyName));
  });
  return isValueNotEmpty(get(bindingForControl, 'validation.onChange.mandatory'));
}
