import _ from 'lodash';
import moment from 'moment';
import * as Qs from 'qs';
import invariant from 'invariant';
import { applicationState } from 'core/state/applicationState';
import { isDefined, isNotDefined, isValueEmpty, isValueNotEmpty } from 'core/util/lang';
import RequestError from 'core/error/requestError';
import FetchError from 'core/error/fetchError';
import createLogger from 'core/logging/logger';
import { wrapError } from 'core/util/error';
import { assertNever } from 'core/util/assertion';
import Promise from 'bluebird';

const logger = createLogger('doRequest');

const globalErrorHandler = [];

export function registerGlobalErrorHandler(handler) {
  globalErrorHandler.push(handler);
}

type BackendOperation = (url: string, options: DoRequestOptions) => Promise<Response>;

let backendOperationMock: BackendOperation = null;

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum ResponseType {
  JSON = 'JSON',
  TEXT = 'TEXT',
}

export enum RequestAsType {
  RAW_JSON = 'RAW_JSON',
  RAW = 'RAW',
  URL_ENCODED = 'URL_ENCODED',
  MULTI_PART = 'MULTI_PART',
}

export interface UrlVariableOption {
  escape: boolean;
  value: string | number;
}

export type UrlVariable = string | number | UrlVariableOption;

export interface GlobalDoRequestOptions {
  method: RequestMethod;
  responseAs?: ResponseType;
  headers?: Record<string, string>;
  urlVariables: Record<string, UrlVariable>;
  credentials: 'same-origin';
}

export interface DoRequestOptions extends Partial<GlobalDoRequestOptions> {
  url?: string;
  requestAs?: RequestAsType;
  data?: any;
  body?: any;
}

export interface DoRequestResponse<T> {
  response: Response;
  cause?: string;
  data: T;
}

const possibleRequestMethods = Object.keys(RequestMethod);
const possibleResponseTypes = Object.keys(ResponseType);
const possibleRequestTypes = Object.keys(RequestAsType);

const optionErrorFormat = `unsupported request option '%s': '%s'. Supported values are %s`;
const urlVariableMissingErrorFormat = `url variable %s is not defined and therefore can't be replaced in '%s'`;

const urlVariableRegexp = /{(\w+)}/g;

export const doRequestGlobals: GlobalDoRequestOptions = {
  method: RequestMethod.GET,
  responseAs: ResponseType.JSON,
  //requestAs: undefined,       // 'RAW' -> JSON.stringify() 'URL_ENCODED' ->
  headers: {
    'x-requested-with': 'XMLHttpRequest',
  },
  urlVariables: {},
  credentials: 'same-origin', // needed to send cookies (session id)
};

export function createJsonBodyPart(name, value) {
  return createBodyPart(name, JSON.stringify(marshall(value)), 'application/json');
}

export function createBodyPart(name, value, type?) {
  invariant(isDefined(name), 'name for body part has to be given');
  invariant(isNotDefined(type) || typeof type === 'string', 'type has to be a string');

  let partValue = isDefined(value) ? value : '';
  if (isDefined(type)) {
    partValue = new Blob([partValue], { type });
  }
  return { name, value: partValue };
}

export function createMultipartRequestBody(bodyParts) {
  let body = new FormData();
  if (isDefined(bodyParts)) {
    for (let part of bodyParts) {
      body.append(part.name, part.value);
    }
  }
  return body;
}

function requestOptionsSanityChecks(options: DoRequestOptions) {
  invariant(!_.isEmpty(options.url), 'please provide a non empty url');

  invariant(
    _.includes(possibleRequestMethods, options.method),
    optionErrorFormat,
    'method',
    options.method,
    possibleRequestMethods
  );

  invariant(
    _.includes(possibleResponseTypes, options.responseAs),
    optionErrorFormat,
    'responseAs',
    options.responseAs,
    possibleResponseTypes
  );

  invariant(
    _.includes(possibleRequestTypes, options.requestAs),
    optionErrorFormat,
    'requestAs',
    options.requestAs,
    possibleRequestTypes
  );
}

function applyDefaultsToRequest(options: Partial<DoRequestOptions>): DoRequestOptions {
  const backendSettings = applicationState.cursor(['configuration', 'backend']);
  const apiSettings = backendSettings.cursor(['API']);

  _.defaults(options, _.cloneDeep(doRequestGlobals));
  _.merge(options.urlVariables, {
    context: {
      value: backendSettings.get('contextPath'),
      escape: false,
    },
    api: {
      value: apiSettings.get('base'),
      escape: false,
    },
    version: {
      value: apiSettings.get('version'),
      escape: false,
    },
  });
  _.merge(options.headers, doRequestGlobals.headers);

  if (!_.has(options.headers, 'Accept')) {
    switch (options.responseAs) {
      case ResponseType.JSON:
        options.headers.Accept = 'application/json';
        break;
      case ResponseType.TEXT:
        options.headers.Accept = 'text/plain';
        break;
      default:
        assertNever(
          options.responseAs,
          () =>
            `unsupported request option 'responseAs': '${options.responseAs}'. Supported values are ${Object.keys(
              ResponseType
            )}`
        );
    }
  }

  if (isValueEmpty(options.requestAs)) {
    switch (options.method) {
      case RequestMethod.GET:
      case RequestMethod.DELETE:
        options.requestAs = RequestAsType.URL_ENCODED;
        break;
      case RequestMethod.POST:
      case RequestMethod.PUT:
      case RequestMethod.PATCH:
        options.requestAs = RequestAsType.RAW_JSON;
        break;
      default:
        assertNever(
          options.method,
          () =>
            `unsupported request option 'method': '${options.method}'. Supported values are ${Object.keys(
              RequestMethod
            )}`
        );
    }
  }

  // DELETE will ignore the body same as GET
  // PUT same as POST
  if (
    (options.method === RequestMethod.POST ||
      options.method === RequestMethod.PUT ||
      options.method === RequestMethod.PATCH) &&
    !_.has(options.headers, 'Content-Type')
  ) {
    switch (options.requestAs) {
      case RequestAsType.RAW_JSON:
        options.headers['Content-Type'] = 'application/json';
        break;
      case RequestAsType.RAW:
        options.headers['Content-Type'] = 'text/plain';
        break;
      case RequestAsType.URL_ENCODED:
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        break;
      case RequestAsType.MULTI_PART:
        // options.headers['Content-Type'] = 'multipart/form-data';
        break;
      default:
        assertNever(options.requestAs, () => 'unsupported requestAs parameter ' + options.requestAs);
    }
  }

  if (!_.has(options, 'body') && _.has(options, 'data')) {
    switch (options.requestAs) {
      case RequestAsType.RAW_JSON: {
        const toSend = marshall(options.data);
        options.body = JSON.stringify(toSend);
        break;
      }
      case RequestAsType.RAW: {
        options.body = options.data;
        break;
      }
      case RequestAsType.URL_ENCODED: {
        options.body = Qs.stringify(options.data);
        break;
      }
      case RequestAsType.MULTI_PART: {
        const formData = new FormData();

        _.forEach(options.data, (data, name) => {
          formData.append(name, data);
        });

        options.body = formData;
        break;
      }
      default:
        assertNever(options.requestAs, () => 'unsupported requestAs parameter ' + options.requestAs);
    }
  }

  if ((options.method === RequestMethod.GET || options.method === RequestMethod.DELETE) && _.has(options, 'body')) {
    if (options.url.indexOf('?') > -1) {
      // url already contains parameters
      options.url = options.url + '&' + options.body;
    } else {
      options.url = options.url + '?' + options.body;
    }
    delete options.body;
  }

  return options as DoRequestOptions;
}

export function marshall(data) {
  return _.cloneDeepWith(data, (value) => {
    if (moment.isMoment(value) || moment.isDate(value)) {
      return moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS');
    }
  });
}

function parseResponse(requestOptions: DoRequestOptions, response) {
  switch (requestOptions.responseAs) {
    case ResponseType.JSON: {
      return response.text().then((text) => {
        if (isValueNotEmpty(text)) {
          try {
            return JSON.parse(text);
          } catch (error) {
            // simply ignore parse error and return undefined!
            logger.warn('failed to parse JSON payload', text, 'return undefined.');
          }
        } else {
          logger.debug('received empty JSON payload! Ignore and return undefined.');
        }
      });
    }
    case ResponseType.TEXT: {
      return response.text();
    }
    default:
      const currentResponseAsValue = requestOptions.responseAs;
      return Promise.try(() => {
        assertNever(currentResponseAsValue, () => 'unsupported responseAs parameter ' + requestOptions.responseAs);
      });
  }
}

function replaceUrlVariables(requestOptions: DoRequestOptions) {
  requestOptions.url = requestOptions.url.replace(urlVariableRegexp, (result, urlVariableName) => {
    let value = requestOptions.urlVariables[urlVariableName];
    let escape = true;

    if (_.isObject(value)) {
      const definition = value;
      escape = _.isBoolean(definition.escape) ? definition.escape : true;
      value = definition.value;
    }

    invariant(isDefined(value), urlVariableMissingErrorFormat, urlVariableName, requestOptions.url);

    if (escape) {
      value = encodeURIComponent(value);
    }

    return '' + value;
  });
}

export function setupDoRequestMock(mockFn) {
  if (!_.isFunction(mockFn)) {
    throw new Error('Please only provide doRequest mock functions');
  }
  backendOperationMock = mockFn;
}

export function resetDoRequestMock() {
  backendOperationMock = null;
}

export const doRequest = <T>(url: string, options?: DoRequestOptions): Promise<DoRequestResponse<T>> => {
  const partialRequestOptions: Partial<DoRequestOptions> = _.isObject(options) ? _.clone(options) : {};

  partialRequestOptions.url = url;

  const requestOptions = applyDefaultsToRequest(partialRequestOptions);

  requestOptionsSanityChecks(requestOptions);

  replaceUrlVariables(requestOptions);

  let backendOperation: BackendOperation;
  if (isDefined(backendOperationMock)) {
    backendOperation = (url, options) => {
      const result = backendOperationMock(url, options);
      if (isDefined(result)) {
        return result;
      } else {
        return Promise.resolve(fetch(url, options));
      }
    };
  } else {
    backendOperation = (url, options) => Promise.resolve(fetch(url, options));
  }

  return Promise.resolve(backendOperation(requestOptions.url, requestOptions))
    .catch((err) => {
      return Promise.reject(
        new FetchError(`failed to fetch [${requestOptions.method}] ${requestOptions.url} because of ${err}`)
      );
    })
    .then((response) => {
      const responseData: DoRequestResponse<T> = {
        response,
        data: null,
      };
      let error = false;

      if (response.status < 200 || response.status >= 400) {
        error = true;
        responseData.cause = 'response status outside positive range';
      }

      const parsingPromise = parseResponse(requestOptions, response);

      return parsingPromise.then((parsedResponse) => {
        let resultPromise: Promise<DoRequestResponse<T>>;

        responseData.data = parsedResponse;

        if (error) {
          resultPromise = Promise.reject(new RequestError(responseData));
        } else {
          resultPromise = Promise.resolve(responseData);
        }

        return resultPromise;
      });
    })
    .catch((err) => {
      const wrappedError = wrapError(err);

      _.forEach(globalErrorHandler, (handler) => {
        handler(wrappedError);
        return !wrappedError.handled;
      });

      return Promise.reject(wrappedError);
    });
};
