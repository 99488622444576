import _ from 'lodash';
import { get, isDefined, isValueNotEmpty, RESOLVE_OBJECT } from 'core/util/lang';
import Immutable, { Iterable } from 'immutable';
import Promise from 'bluebird';
import { doRequest, DoRequestResponse } from 'core/backend/doRequest';
import { wrapError } from 'core/util/error';
import { resolveData } from 'core/util/promises';
import RequestError from 'core/error/requestError';

export type Link = {
  rel: string;
  href?: string;
} & any;

export interface Linkable {
  links?: Link[];
}

export type Links = Linkable | Link[] | Iterable.Indexed<Link>;

/**
 * extract the link for a specific relation
 * @param {string} [rel] name of the relation to query for
 * @param {Link[] | Linkable} [links] collection of links
 * @param {string} [targetProp]  the property to extract
 * @returns {string|null}
 */
// default rel with _will_never_be_found to avoid false positives if
// caller calls with rel = undefined
export function follow(rel: string = '_will_never_be_found', links: Links = [], targetProp: string = 'href'): any {
  let resolvedLinks: Link[] | Iterable.Indexed<Link>;

  if (_.isArray(links) || isIndexed(links)) {
    resolvedLinks = links;
  } else {
    resolvedLinks = get(links, 'links') ?? [];
  }

  const value = get(
    resolvedLinks.find((toInspect) => get(toInspect, 'rel') === rel),
    targetProp
  );
  return value ?? null;
}

/**
 * Helper function for correct typing. True if `maybeIndexed` is an Iterable.Indexed, or any of its subclasses.
 */
function isIndexed(maybeIndexed: any): maybeIndexed is Iterable.Indexed<any> {
  return Immutable.Iterable.isIndexed(maybeIndexed);
}

/**
 * test if a link with a specific relation exists
 * @param rel name of the relation to query for
 * @param links collection of links
 * @param targetProp  the property to extract
 * @returns {boolean}
 */
export function hasLink(rel: string, links: Links = [], targetProp: string = 'href'): boolean {
  return isValueNotEmpty(follow(rel, links, targetProp));
}

/**
 * extract the link for a specific relation
 * @param rel name of the relation to query for
 * @param links collection of links
 * @param targetProp  the property to extract
 * @returns {string[]|null}
 */
// default rel with _will_never_be_found to avoid false positives if
// caller calls with rel = undefined
export function followMultiple(
  rel: string = '_will_never_be_found',
  links: Links = [],
  targetProp: string = 'href'
): any[] {
  let resolvedLinks;

  if (_.isArray(links)) {
    resolvedLinks = links;
  } else if (Immutable.Iterable.isIndexed(links)) {
    resolvedLinks = (links as Immutable.Iterable.Indexed<Link>).toArray();
  } else {
    resolvedLinks = get(links, 'links', RESOLVE_OBJECT) ?? [];
  }

  const hrefList = [];
  _.forEach(
    resolvedLinks.filter((toInspect) => get(toInspect, 'rel') === rel),
    (value) => {
      hrefList.push(get(value, targetProp));
    }
  );

  return hrefList;
}

export function followLink<T>(linkName: string, propertyName?: string): (data: Linkable) => Promise<T> {
  return (data) => {
    if (isDefined(data)) {
      const link = follow(linkName, get(data, 'links'), propertyName);
      if (isDefined(link)) {
        return doRequest<T>(link)
          .then(resolveData)
          .catch((err) => {
            throw wrapError(err);
          });
      } else {
        return Promise.reject(new Error(`link "${linkName}" not found`));
      }
    } else {
      return Promise.reject(new Error('no data given'));
    }
  };
}

export function fetchCreated<Resp = any, Req = any>(
  response: DoRequestResponse<Req>
): Promise<DoRequestResponse<Resp>> {
  const actualResponse = response.response;
  if (actualResponse?.headers.has('location')) {
    return doRequest(actualResponse.headers.get('location'));
  } else {
    response.cause = 'Missing expected location header in response';
  }
  return Promise.reject(new RequestError(response));
}
