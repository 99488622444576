import { substitute } from 'core/logging/loggerBase';
import Immutable from 'immutable';
import immstruct from 'immstruct';
import { doRequest } from 'core/backend/doRequest';

const cacheStructure = immstruct({ cache: [] });
const cacheReference = cacheStructure.reference('cache');

cacheStructure.on('next-animation-frame', () => {
  cacheReference.cursor().forEach(logEntry => {
    doRequest('{context}/common/logJavaScriptError.do', {
      method: 'POST',
      requestAs: 'URL_ENCODED',
      data: {
        level: logEntry.level.toUpperCase(),
        errorMsg: substitute(logEntry.message, logEntry.data),
        url: window.location.href
      }
    });
  });
  cacheReference.cursor().set(Immutable.List());
});

export default function(logEntry) {
  cacheReference.cursor().push(logEntry);
}
