/* eslint-disable no-inner-declarations */
import _ from 'lodash';
import { get, isDefined } from 'core/util/lang';
import { wrapError } from 'core/util/error';
import Promise, { Inspection } from 'bluebird';

export type ValueOrPromiseLike<T> = T | PromiseLike<T>;

export function isPromiseLike<T>(toCheck: ValueOrPromiseLike<T>): toCheck is Promise<T> {
  // @ts-ignore
  return isDefined(toCheck) && _.isFunction(toCheck.then);
}

export function wrap<T>(possiblePromise: ValueOrPromiseLike<T>): Promise<T> {
  let resultPromise;
  if (isPromiseLike(possiblePromise)) {
    resultPromise = possiblePromise;
  } else {
    resultPromise = Promise.resolve(possiblePromise);
  }

  return resultPromise;
}

function setSync<T, TResult = T>(
  method: (value: T) => TResult | PromiseLike<TResult> | null | undefined,
  value: T
): TResult | PromiseLike<TResult> | null | undefined {
  return method(value);
}

export function callWithResolved<T, TResult = T>(
  method: (value: T) => TResult,
  promise: ValueOrPromiseLike<T>
): Promise<TResult> {
  let result;

  if (isPromiseLike(promise)) {
    if (promise.isFulfilled()) {
      setSync(method, promise.value());
      result = promise;
    } else {
      result = promise.then(method);
    }
  } else {
    // no promise so interpret promise as normal value to set
    result = setSync(method, promise);
  }

  return result;
}

export function updateState(componentReference, propertyName, overrideValue?) {
  return Promise.method((value) => {
    const valueToSet = isDefined(overrideValue) ? overrideValue : value;
    componentReference.setState({
      [propertyName]: valueToSet,
    });
    return value;
  });
}

export function resolveData<T>(response: { data?: T }): Promise<T> {
  return Promise.resolve(get(response, 'data'));
}

export function resolveEntityList<T>(result: { entityList?: T }): Promise<T> {
  return Promise.resolve(get(result, 'entityList'));
}

export function cancel<T>(promise: Promise<T> | Array<Promise<T>>): void {
  if (promise instanceof Array) {
    _.forEach(promise, (promiseFromArray) => promiseFromArray.cancel());
  } else if (isDefined(promise)) {
    promise.cancel();
  }
}

export function isFulfilled<T>(promise: Promise<T>): boolean {
  if (isDefined(promise) && _.isFunction(promise.isFulfilled)) {
    return promise.isFulfilled();
  }
  return false;
}

export function valueOf<T>(promise: Promise<T>): T {
  if (isDefined(promise) && _.isFunction(promise.value)) {
    return promise.value();
  }
  return undefined;
}

export function isRejected<T>(promise: Promise<T>): boolean {
  if (isDefined(promise) && _.isFunction(promise.isRejected)) {
    return promise.isRejected();
  }
  return false;
}

export function reasonOf<T>(promise: Promise<T> | Inspection<T>): any {
  if (isDefined(promise) && _.isFunction(promise.reason)) {
    return promise.reason();
  }
  return undefined;
}

export function serialMappedSettleAll<T, M = T>(
  iterable: Iterable<T | Promise<T>>,
  mapper: (value: T | Promise<T>) => M
): Promise<Array<Inspection<M>>> {
  const results = [];
  let resolveResultPromise = () => {};
  const resultPromise = new Promise<Array<Inspection<M>>>((resolve) => {
    resolveResultPromise = () => resolve(results);
  });

  if (isDefined(iterable) && isDefined(iterable[Symbol.iterator])) {
    const iterator = iterable[Symbol.iterator]();

    const promisifiedMapper = Promise.method(mapper);

    // @ts-ignore
    function next() {
      let current = iterator.next();
      if (current.done === false) {
        promisifiedMapper(current.value)
          .reflect()
          .tap((introspection) => results.push(introspection))
          .finally(next);
      } else {
        resolveResultPromise();
      }
    }

    next();
  } else {
    resolveResultPromise();
  }

  return resultPromise;
}

type PromiseType<T> = T extends Promise<infer U> ? U : never;
type PromisesInspected<T extends readonly Promise<unknown>[]> = { [K in keyof T]: Inspection<PromiseType<T[K]>> };

export function settleAll<P extends readonly Promise<unknown>[]>(promises: P): Promise<PromisesInspected<P>>;
export function settleAll<P extends readonly Promise<unknown>[]>(...promises: P): Promise<PromisesInspected<P>>;
export function settleAll<P extends Promise<unknown>[]>(...promises: P | P[]): Promise<PromisesInspected<P>> {
  const flattenedPromises = _.flatten(promises);
  return Promise.all(
    flattenedPromises.map((promise) => {
      // ensure all values provided to settleAll are promises
      // else wrap on the fly to ensure API compatibility
      return wrap(promise).reflect();
    })
  ) as Promise<PromisesInspected<P>>;
}

export function exec(toExec) {
  return Promise.try(() => {
    if (_.isFunction(toExec)) {
      return toExec();
    } else {
      return toExec;
    }
  }).catch((err) => {
    return Promise.reject(wrapError(err));
  });
}

// wait at least until resolution time has passed
// fast bail out on error
export function minResolutionTime<T>(resolutionTime: number, promise: Promise<T>): Promise<T> {
  // @ts-ignore
  return Promise.map([promise, Promise.delay(resolutionTime, 'resolved')], _.identity, {
    concurrency: 2,
  }).get(0);
}
