import { applicationState } from 'core/state/applicationState';
import { get, isDefined, set } from 'core/util/lang';
import _ from 'lodash';

function cleanupStateOverride(state) {
  const cleanedUpState = _.cloneDeep(state);

  let contextPath = get(cleanedUpState, 'configuration.backend.contextPath');
  if (isDefined(contextPath)) {
    if (contextPath.charAt(contextPath.length - 1) === '/') {
      contextPath = contextPath.substring(0, contextPath.length - 1);
      set(cleanedUpState, 'configuration.backend.contextPath', contextPath);
    }
  }

  return cleanedUpState;
}

export default function applicationStateDefaultsHandler(defaultsToApply) {
  return () => {
    applicationState.cursor().mergeDeep(defaultsToApply);
    // override default application state
    applicationState.cursor().mergeDeep(cleanupStateOverride(window.stateOverride));
  };
}
