import _ from 'lodash';

/**
 * Indicates an error during fetch() API calls (unmarshall)
 *
 * Error receipt:
 * needed because babel cannot subclass native Error objects without hacking the __proto__ chain
 * http://stackoverflow.com/questions/33870684/why-doesnt-instanceof-work-on-instances-of-error-subclasses-under-babel-node
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
 *
 * @param response
 * @constructor
 */
export default function RequestError(response) {
  this.name = 'RequestError';
  this.message = response.cause;
  this.response = response;
  _.assign(this, response);
  this.stack = new Error().stack;
}
RequestError.prototype = Object.create(Error.prototype);
RequestError.prototype.constructor = RequestError;
