import { isValueNotEmpty, result } from 'core/util/lang';
import invariant from 'invariant';
import _ from 'lodash';

export function assert(condition: boolean, message: string): asserts condition {
  invariant(condition, message);
}

export function assertIsArray<T>(property: T[] | any, message: string): asserts property is T[] {
  assert(_.isArray(property), message);
}

export function mandatory(property: any, propertyName: string) {
  assert(isValueNotEmpty(property), `property '${propertyName}' is mandatory and should not be empty`);
}

export function isFunction(property: any, propertyName: string): asserts property is (...args: any[]) => any {
  assert(_.isFunction(property), `property '${propertyName}' must be a function`);
}

/**
 *  Check discriminated values for exhaustiveness
 *  http://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
 */
export function assertNever(x: never, message?: string | (() => string)): never {
  if (isValueNotEmpty(message)) {
    throw new Error(result(message));
  } else {
    throw new Error('Unexpected object: ' + x);
  }
}
