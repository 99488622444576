import bowser from 'bowser';

export const BROWSER_NAMES = {
  IE: 'Internet Explorer',
  EDGE: 'Microsoft Edge',
  SAFARI: 'Safari',
  CHROME: 'Chrome',
  FIREFOX: 'Firefox'
};

// detailed information about the device and browser detection
// https://github.com/ded/bowser

export function getDeviceInfo() {
  return bowser.getParser(window.navigator.userAgent);
}
