import { AFTER_SESSION_CHECK } from 'core/boot/constants';
import { applicationState } from 'core/state/applicationState';
import { isDefined, set } from 'core/util/lang';
import { doRequest } from 'core/backend/doRequest';

export const SYSTEM_SETTINGS = 'configuration.settings.system';

export function settingsHandler() {
  return doRequest('{context}/{api}/{version}/sysSettings')
    .then(result => {
      if (isDefined(result.data)) {
        set(applicationState, SYSTEM_SETTINGS, result.data);
      }
      return result;
    })
    .catch(error => {
      console.error('Could not retrieve system settings configuration.');
      return Promise.reject(error);
    });
}

export function register(initializer) {
  initializer.callback(AFTER_SESSION_CHECK, settingsHandler);
}
