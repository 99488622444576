import React from 'react';
import { get } from 'core/util/lang';
import { applicationState } from 'core/state/applicationState';

export default class BootScreenFooter extends React.Component {
  static key = 'BootScreenFooter';

  render() {
    return (
      <div className="footer">
        <hr />
        {get(applicationState, 'boot.components.footer.infoText')}
      </div>
    );
  }
}
