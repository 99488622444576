export const UN_INITIALIZED = 'UNINITIALIZED';
export const START_INITIALIZATION = 'START_INITIALIZATION';

export const BEFORE_SESSION_CHECK = 'BEFORE_SESSION_CHECK';
export const SESSION_CHECK = 'SESSION_CHECK';
export const AFTER_SESSION_CHECK = 'AFTER_SESSION_CHECK';

export const BEFORE_SETTING_UP_SYSTEMS = 'BEFORE_SETTING_UP_SYSTEMS';
export const SETTING_UP_SYSTEMS = 'SETTING_UP_SYSTEMS';
export const AFTER_SETTING_UP_SYSTEMS = 'AFTER_SETTING_UP_SYSTEMS';

export const FINISHED_INITIALIZATION = 'FINISHED_INITIALIZATION';

export const ERROR_INITIALIZATION = 'ERROR_INITIALIZATION';

export const APPLICATION_LOADED = 'APPLICATION_LOADED';

export const COMPLETED = 'COMPLETED';

export const BOOT_ORDER = [
  UN_INITIALIZED,

  START_INITIALIZATION,

  BEFORE_SESSION_CHECK,
  SESSION_CHECK,
  AFTER_SESSION_CHECK,

  BEFORE_SETTING_UP_SYSTEMS,
  SETTING_UP_SYSTEMS,
  AFTER_SETTING_UP_SYSTEMS,

  FINISHED_INITIALIZATION
];
