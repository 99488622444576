import _ from 'lodash';
import moment from 'moment';
import Immutable from 'immutable';
import invariant from 'invariant';

type PropertyPath = string[];
export type PotentialPropertyPath = string | string[];
export const RESOLVE_OBJECT = {
  resolve: 'jsobject',
};

type GetStrategy = typeof RESOLVE_OBJECT;

// checks if a given variable is defined, meaning not undefined and not null
export function isDefined(toCheck: any): boolean {
  return !_.isUndefined(toCheck) && !_.isNull(toCheck) && !_.isNaN(toCheck);
}

export const isNotDefined = _.negate(isDefined);

function checkProperty(property: any): void {
  invariant(
    _.isString(property) || _.isArray(property) || (_.isNumber(property) && property - Math.floor(property) === 0),
    'property must be a string or integer.'
  );
}

function checkObject(object: any): void {
  invariant(isDefined(object), 'object must be defined.');
}

function applyStrategyToResult(original: any, strategy: GetStrategy) {
  let originalEnhanced;

  if (isDefined(original) && get(strategy, 'resolve') === 'jsobject') {
    originalEnhanced = resolveValue(original);
  } else {
    originalEnhanced = original;
  }

  return originalEnhanced;
}

export function toPath(propertyPath: PotentialPropertyPath): PropertyPath {
  if (_.isString(propertyPath)) {
    return _.toPath(propertyPath);
  } else {
    return propertyPath;
  }
}

export function mergePath(pathA: PotentialPropertyPath, pathB: PotentialPropertyPath): PropertyPath {
  return _.concat(_.toPath(pathA), toPath(pathB));
}

export function resolveValue(value: any): any {
  if (Immutable.Iterable.isIterable(value)) {
    return value.toJS();
  } else if (isDefined(value) && _.isFunction(value.cursor)) {
    return resolveValue(value.cursor().deref());
  } else {
    return value;
  }
}

export function get(
  object: Immutable.Iterable<unknown, unknown> | { cursor: () => Immutable.Iterable<unknown, unknown> } | unknown,
  property: PotentialPropertyPath,
  strategy?: GetStrategy
) {
  let getResult;

  if (isDefined(property)) {
    checkProperty(property);
    const propertyPath = toPath(property);

    // TODO: How to properly type this without ts-ignore
    if (Immutable.Iterable.isIterable(object)) {
      // @ts-ignore
      getResult = object.getIn(propertyPath);
      getResult = applyStrategyToResult(getResult, strategy);
    } else {
      // @ts-ignore
      if (isDefined(object) && _.isFunction(object.cursor)) {
        // @ts-ignore
        getResult = object.cursor().getIn(propertyPath);
        getResult = applyStrategyToResult(getResult, strategy);
      } else {
        getResult = _.get(object, propertyPath);
      }
    }
  } else {
    getResult = null;
  }

  return getResult;
}

export function set(object: any, property: PotentialPropertyPath, toSet: any) {
  checkObject(object);
  let setResult;

  if (isDefined(property)) {
    checkProperty(property);
    const propertyPath = toPath(property);

    if (_.isString(object)) {
      throw new Error('Operation set not supported on strings.');
    } else if (Immutable.Iterable.isIterable(object)) {
      setResult = object.setIn(propertyPath, toSet);
    } else if (isDefined(object) && _.isFunction(object.cursor)) {
      setResult = object.cursor().setIn(propertyPath, toSet);
    } else {
      setResult = _.set(object, propertyPath, toSet);
    }
  } else {
    setResult = object;
  }

  return setResult;
}

export function toBoolean(str: any, silent = true): boolean {
  let toBooleanResult;

  if (_.isBoolean(str)) {
    toBooleanResult = str;
  } else if (str === 'true') {
    toBooleanResult = true;
  } else if (str === 'false') {
    toBooleanResult = false;
  }

  if (isDefined(toBooleanResult)) {
    return toBooleanResult;
  } else {
    if (silent) {
      return false;
    } else {
      throw new Error(`cannot interpret value [${str}] as boolean`);
    }
  }
}

export function isValueEmpty<T>(toInspect: any): boolean {
  let isValueEmptyResult;

  if (_.isString(toInspect)) {
    isValueEmptyResult = _.isEmpty(_.trim(toInspect));
  } else if (_.isNumber(toInspect)) {
    isValueEmptyResult = !isDefined(toInspect);
  } else if (
    _.isBoolean(toInspect) ||
    _.isRegExp(toInspect) ||
    _.isDate(toInspect) ||
    _.isFunction(toInspect) ||
    moment.isMoment(toInspect)
  ) {
    isValueEmptyResult = false;
  } else {
    isValueEmptyResult = _.isEmpty(toInspect);
  }

  return isValueEmptyResult;
}

export function isValueNotEmpty<T>(toInspect: void | null | undefined | T): toInspect is T {
  return !isValueEmpty(toInspect);
}

export const stringToArray = (string: string, delimiter = ','): string[] => {
  return _.compact(_.map(_.split(string, delimiter), (value) => _.trim(value)));
};

export function callHandler<A extends any[], R>(handlerFn: (...args: A) => R, ...args: A): R {
  if (_.isFunction(handlerFn)) {
    return handlerFn(...args);
  }
}

export function callHandlerWithDefault<A extends [], R>(
  handlerFn: (...args: A) => R,
  defaultFn: (...args: A) => R,
  ...args: A
): R {
  if (_.isFunction(handlerFn)) {
    return handlerFn(...args);
  } else {
    invariant(_.isFunction(defaultFn), 'default function must be a function');
    return defaultFn(...args);
  }
}

/**
 * compare two numbers if they are nearly equal
 * solves common comparison problems like 0.3 === 0.1 + 0.2 / false
 * operates within physical resolution limits (Number.EPSILON)
 * based on suggestions http://floating-point-gui.de/errors/comparison/
 * @param a
 * @param b
 * @returns {boolean}
 */
export function nearlyEqual(a: number, b: number) {
  // shortcut if really equal
  // eslint-disable-next-line eqeqeq
  if (a == b) {
    return true;
  } else {
    // shortcut if no number at all
    if (_.isNaN(a) && _.isNaN(b)) {
      return true;
    } else {
      // check if the difference is smaller than the smallest Number representation (Number.EPSILON)
      return Math.abs(a - b) / Math.min(Math.abs(a) + Math.abs(b), Number.MAX_VALUE) < Number.EPSILON;
    }
  }
}

export function unflatten(data: any, delimiter = '.'): any {
  if (isValueEmpty(data)) return data;

  if (!_.isArray(data) && !_.isPlainObject(data)) return data;
  //    /\.?([^.\[\]]+)|\[(\d+)\]/g,
  const regex = new RegExp('\\' + delimiter + '?([^' + delimiter + '\\[\\]]+)|\\[(\\d+)\\]', 'g');
  const resultholder: any = {};
  for (let p in data) {
    if (data.hasOwnProperty(p)) {
      let cur = resultholder,
        prop = '',
        m;
      while ((m = regex.exec(p))) {
        cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
        prop = m[2] || m[1];
      }
      cur[prop] = data[p];
    }
  }
  return resultholder[''] || resultholder;
}

export function flatten(data: any, delimiter = '.', keepArrays = false) {
  if (isValueEmpty(data)) return data;

  if (!_.isArray(data) && !_.isPlainObject(data)) {
    return data;
  }

  const flattenResult: any = {};

  function recurse(cur: any, prop: string) {
    if (Object(cur) !== cur) {
      flattenResult[prop] = cur;
    } else if (Array.isArray(cur)) {
      if (keepArrays) {
        flattenResult[prop] = cur;
      } else {
        for (let i = 0; i < cur.length; i++) recurse(cur[i], prop + '[' + i + ']');
        if (cur.length === 0) flattenResult[prop] = [];
      }
    } else {
      for (let p in cur) {
        if (cur.hasOwnProperty(p)) {
          recurse(cur[p], prop ? prop + delimiter + p : p);
        }
      }
    }
  }

  recurse(data, '');
  return flattenResult;
}

export function hash(str: string) {
  let hashResult = 5381,
    i = str.length;

  while (i) {
    hashResult = (hashResult * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hashResult >>> 0;
}

export function swap<T>(array: T[], sourceIndex: number, destinationIndex: number): T[] {
  if (!_.isArray(array)) {
    throw new Error(`Expected array but object of type '${typeof array}' was given`);
  }

  const length = array.length;
  if (sourceIndex < 0 || destinationIndex < 0 || sourceIndex >= length || destinationIndex >= length) {
    throw new Error('index out of bounds');
  }

  const sourceItem = array[sourceIndex];
  const destinationItem = array[destinationIndex];
  const diff = sourceIndex - destinationIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, destinationIndex),
      sourceItem,
      ...array.slice(destinationIndex + 1, sourceIndex),
      destinationItem,
      ...array.slice(sourceIndex + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    return [
      ...array.slice(0, sourceIndex),
      destinationItem,
      ...array.slice(sourceIndex + 1, destinationIndex),
      sourceItem,
      ...array.slice(destinationIndex + 1, length),
    ];
  }
  return array;
}

export function reorder<T>(array: T[], sourceIndex: number, destinationIndex: number): T[] {
  if (!_.isArray(array)) {
    throw new Error(`Expected array but object of type '${typeof array}' was given`);
  }

  const length = array.length;
  if (sourceIndex < 0 || destinationIndex < 0 || sourceIndex >= length || destinationIndex >= length) {
    throw new Error('index out of bounds');
  }

  const reorderResult = Array.from(array);
  const [removed] = reorderResult.splice(sourceIndex, 1);
  reorderResult.splice(destinationIndex, 0, removed);

  return reorderResult;
}

export function selectDeep(toCheck: any, iteratee: any): any[] {
  const matcher = _.iteratee(iteratee);
  let selectDeepResult: any[] = [];

  if (matcher(toCheck)) {
    selectDeepResult.push(toCheck);
  }

  if (_.isArray(toCheck) || _.isPlainObject(toCheck)) {
    _.forEach(toCheck, (value) => {
      selectDeepResult = _.concat(selectDeepResult, selectDeep(value, iteratee));
    });
  }

  return selectDeepResult;
}

export function result<T, A extends any[]>(toCheck: T | ((...args: A) => T), ...args: A): T {
  if (_.isFunction(toCheck)) {
    return toCheck(...args);
  }
  return toCheck;
}
