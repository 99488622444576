import _ from 'lodash';
import invariant from 'invariant';
import { get, isDefined } from 'core/util/lang';
import urljoin from 'url-join';
import URL from 'url-parse';
import { decode } from 'querystring';
import { applicationState } from 'core/state/applicationState';

const urlVariableMissingErrorFormat = `url variable %s is not defined and therefor can't be replaced`;
const urlVariableRegexp = /{(\w+)}/g;

const backendSettings = applicationState.reference(['configuration', 'backend']);
const apiSettings = backendSettings.reference('API');

function replaceUrlVariables(pattern, urlVariables, query) {
  const baseURL = pattern.replace(urlVariableRegexp, (result, urlVariableName) => {
    let value = get(urlVariables, urlVariableName);
    let escape = true;

    if (_.isObject(value)) {
      const definition = value;
      escape = _.isBoolean(definition.escape) ? definition.escape : true;
      value = definition.value;
    }

    invariant(isDefined(value), urlVariableMissingErrorFormat, urlVariableName);

    if (escape) {
      value = encodeURIComponent(value);
    }

    return value;
  });

  const parsedURL = new URL(baseURL, ' ', true);

  const mergedQuery = _.omitBy(_.merge({}, parsedURL.query, query), _.isUndefined);
  parsedURL.set('query', mergedQuery);

  return _.trim(parsedURL.toString());
}

export default {
  build(pattern, urlVariables, query = {}) {
    const variables = _.merge(
      {
        context: {
          value: get(backendSettings, 'contextPath'),
          escape: false,
        },
        api: {
          value: get(apiSettings, 'base'),
          escape: false,
        },
        version: {
          value: get(apiSettings, 'version'),
          escape: false,
        },
      },
      urlVariables
    );

    return replaceUrlVariables(pattern, variables, query);
  },
  /**
   * concats window.document.baseURI with the route
   * @param route {string}
   * @returns {string}
   */
  absoluteUrlFromRoute(route) {
    // history api now respects base uri settings so only ensure
    // that the url is absolute
    let baseUri = window.location.origin;

    if (_.startsWith(route, 'http')) {
      return route;
    } else {
      return urljoin(baseUri, route);
    }
  },
  parseQueryString(url) {
    return decode(_.trimStart(_.trim(url), '?'));
  },
};
