import { AFTER_SESSION_CHECK } from 'core/boot/constants';
import { registerGlobalErrorHandler } from 'core/backend/doRequest';
import doRequestSessionErrorHandler from 'core/backend/doRequestSessionErrorHandler';

export function registerDoRequestSessionErrorHandler() {
  registerGlobalErrorHandler(doRequestSessionErrorHandler);
}

export function register(initializer) {
  initializer.callback(AFTER_SESSION_CHECK, registerDoRequestSessionErrorHandler);
}
