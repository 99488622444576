import { START_INITIALIZATION } from 'core/boot/constants';
import getLogger from 'core/logging/logger';
import backendTransport from 'core/logging/backendTransport';

export function registerLoggerBackendTransport() {
  // create global backend push logger
  getLogger('backend', 'error', [backendTransport]);
}

export function register(initializer) {
  initializer.callback(START_INITIALIZATION, registerLoggerBackendTransport);
}
