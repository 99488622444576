import { BEFORE_SESSION_CHECK } from 'core/boot/constants';
import { doRequest, doRequestGlobals } from 'core/backend/doRequest';
import { applicationState } from 'core/state/applicationState';

export function csrfTokenHandler() {
  return doRequest('{context}/{api}/csrftoken').then(result => {
    applicationState.cursor('session').set('csrfToken', result.data);
    doRequestGlobals.headers['X-CSRF-TOKEN'] = result.data;
    return result.data;
  });
}

export function register(initializer) {
  initializer.callback(BEFORE_SESSION_CHECK, csrfTokenHandler);
}
