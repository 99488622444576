import { ERROR_TYPES } from 'core/errorTypes';
import _ from 'lodash';
import { notify, SEVERITY } from 'core/service/shell/notification';
import { translate } from 'core/i18n/translate';
import session from 'core/state/session';
import React from 'react';
import Loadable from 'react-loadable';

const LazyLoadedButton = Loadable({
  loader: () => import('../component/control/input/button'),
  loading: () => <div>Loading...</div>,
});

export default function (wrappedError) {
  if (
    wrappedError.type === ERROR_TYPES.FORBIDDEN &&
    (_.startsWith(wrappedError.message, 'Invalid CSRF Token') ||
      _.startsWith(wrappedError.message, 'Could not verify the provided CSRF token'))
  ) {
    notify({
      message: translate('clientcore.error.session.invalidCSRFToken.message'),
      severity: SEVERITY.ERROR,
      action: (
        <LazyLoadedButton onClick={session.reloadBecauseOfInvalidSession}>
          {translate('clientcore.error.session.invalidCSRFToken.action')}
        </LazyLoadedButton>
      ),
    });

    wrappedError.handled = true;
  }
}
